.dashboard {
    background: var(--pallate-blue-gray-800, #101010);
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.get-started-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    position: relative;
}

.credits_info{
    display: flex;
    flex-direction: row;
    gap:8px;
    margin-right: 50px;
}

.get-started-contentbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.create-button {
    display: flex;
    width: 100%;
    max-width: 601px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: none;
    background: #9746FF;
}

.create-button:hover {
    cursor: pointer;
}

.project-container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    max-width: 1540px;
    width: 100%;
    gap: 24px;
}

.projects {
    display: flex;
    align-self: stretch;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--pallate-blue-gray-700, #334155);
}

.credits_info{
    display: flex;
    align-items: end;
    flex-direction: row;
    gap:8px;
    margin-right: 50px;
}

.all-projects {
    display: flex;
    height: 188px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--pallate-blue-gray-600, #475569);
    background: var(--pallate-blue-gray-800, #101010);
    margin-bottom: 20px;
}

.add-project-icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
}

.my-projects {
    display: flex;
    height: 228px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch; 
    overflow-x: auto;  
}
.my-projects::-webkit-scrollbar{
    display: none;
}
.dataSource-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.headings {
    display: flex;
    align-items: flex-start;
}

.tab-menu  {
    display: flex;
    height: 12px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid var(--pallate-cyan-500, #06B6D4);
    background: var(--pallate-gray-800, #1F2937);
}

.tab-menu:hover {
    cursor: pointer;
}

.tab-inactive{
    display: flex;
    height: 12px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #475569;
    border-bottom: 2px solid var(--pallate-cyan-500, #FFF);
    background: var(--pallate-gray-800, #1F2937);
}

.data {
    display: flex;
    align-items: flex-start;
    gap: 20px;
align-self: stretch;
    overflow-x: auto;
}
.data::-webkit-scrollbar{
    display: none;
}

.data-box {
    display: flex;
max-width: 198px;
width: 100%;
height: 242px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
gap: 25px;
border-radius: 8px;
border: 1px solid var(--pallate-blue-gray-500, #64748B);
background: var(--pallate-blue-gray-600, #475569);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.file-icon {
    display: flex;
    max-width: 120px;
    width: 100%;
    height: 120px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.file-name {
    overflow: hidden;
    /* text-align: center; */
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    max-width: 128px;
    width: 100%;
}
.csv{
    width: 116px;
height: 116px;
flex-shrink: 0;
}
.file-details{
    display: flex;
padding: 12px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 0px 0px 8px 8px;
border-top: 1px solid var(--pallate-blue-gray-700, #334155);
background: var(--pallate-blue-gray-800, #101010);
}

.project-settings {
    display: flex;
    width: 702.5px;
    height:24px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    position: absolute;
    right: 27.5px;
    top: 16px;
}

.project-settting-icons {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.saved-data-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
align-self: stretch;
    overflow-x: auto;
}
.saved-data-container::-webkit-scrollbar{
    display: none;
}

.saved-data {
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 198px;
height: 242px;
    width: 100%;
gap: 12px;
border-radius: 8px;
border: 1px solid var(--pallate-blue-gray-500, #64748B);
background: var(--pallate-blue-gray-600, #475569);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.vizualization-details {
    display: flex;
    padding: 12px;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
border-top: 1px solid var(--pallate-blue-gray-700, #334155);
background: var(--pallate-blue-gray-800, #101010);
}

.viz-options {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0px 3.25px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 7px;
}

.vizualization {
    display: flex;
    width: 150px;
    height: 150px;
    padding: 17px 17.176px 16.676px 16.5px;
    justify-content: center;
    align-items: center;
}
.canvas-image{
    width: 116.324px;
    height: 116.324px;
    flex-shrink: 0;
}

.save-more {
    display: flex;
    flex-shrink: 0;
    max-width: 198px;
    width: 100%;
    padding: 20px;
    height: 202px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--pallate-blue-gray-600, #475569);
    background: var(--pallate-blue-gray-800, #101010);
}
.save-more:hover{
cursor: pointer;
}
.options-icon{
    width: 17.5px;
height: 3.5px;
flex-shrink: 0;
}
.options-icon:hover{
    cursor: pointer;
}
.text-scroll:hover{
    display: inline-block;
    animation: file-name 12s linear infinite;
    padding: 0 15rem;
    
}
.text-scroll::-webkit-scrollbar{
    display: none;
}
.input-form{
    width: 100%;
}
.dashboard-search{
    display: flex;
padding: 12px;
align-items: center;
gap: 8px;
flex: 1 0 0;
border-radius: 4px 0px 0px 4px;
border-top: 1px solid var(--light-forms-border, #CBD5E1);
border-bottom: 1px solid var(--light-forms-border, #CBD5E1);
border-left: 1px solid var(--light-forms-border, #CBD5E1);
background: var(--light-forms-background, #FFF);
}
.searchInput{
    width: 100%;
    outline: none;
    color: var(--light-text-secondary-text, #64748B);

/* Text / sm / normal-400 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
border:none;
}
.search-container{
    display: flex;
    flex-direction: column;
align-items: flex-start;
gap: 24px;
align-self: stretch;
}
.search-button{
    display: flex;
width: 124px;
padding: 12px;
justify-content: center;
align-items: center;
border-radius: 0px 4px 4px 0px;
background: #9746FF;
border:none
}
.a-z{
    display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 4px;
background: var(--pallate-blue-gray-600, #475569);
cursor: pointer;
}
.a-zIcon{
    display: flex;
width: 24px;
height: 24px;
/* padding: 4.25px 4.983px 4.25px 4.878px; */
justify-content: center;
align-items: center;
}
.getstarted-mob{
    display:none;
}
.search-container-dash{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.search-box{
    display: flex;
justify-content: center;
align-items: flex-start;
gap: 24px;
align-self: stretch;
}

/*  */
.recent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.recent-container{
    display: flex;
    flex-direction: row;
width: 100%;
align-items: flex-start;
gap: 31px;
flex-wrap: wrap;
overflow-y: auto;
}
.recent-container::-webkit-scrollbar{
    display: none;
}
.projrcts-file{
    display: flex;
    width: 198px;
    height: 220px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    gap: 25px;
    border-radius: 8px;
border: 1px solid var(--pallate-blue-gray-500, #64748B);
background: var(--pallate-blue-gray-600, #475569);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);

}
.char-image{
    width: 120px;
height: 120px;
flex-shrink: 0;
}
.chart-details{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
border-top: 1px solid var(--pallate-blue-gray-700, #334155);
background: var(--pallate-blue-gray-800, #101010);
}
.back-dash{
    display: inline-flex;
padding: 8px;
align-items: flex-end;
gap: 8px;
border-radius: 8px;
/* background: var(--pallate-blue-gray-600, #475569); */
cursor: pointer;
margin:41px 0px 0px 32px;
}
.dash-back-icon{
    display: flex;
    width: 32px;
    height: 32px;
    /* padding: 0px 3.25px; */
    justify-content: center;
    align-items: center;   
}
.projects-dash{
    display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-radius: 8px;
background: rgba(15, 23, 42, 0.50);
}
.back-dash-container{
    display: inline-flex;
    align-items: center;
    gap: 12px;
}
.files-container{
    margin-top: 24px;
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--pallate-blue-gray-700, #334155);
    background: var(--light-utility-secondary-active, #334155);

}
.scroll::-webkit-scrollbar{
 display: none;
}
.input-and-button{
    display: flex;
align-items: center;
flex: 1 0 0;
}
   @keyframes file-name{
    0%{
        transform:translate(0)
    }
    100%{
        transform:translate(-100%)
    }
} 
@media screen and (max-width: 600px){
    .dashboard {
       display: none;
    }

    .credits_info{
        display: flex;
        flex-direction: row;
        gap:8px;
        margin-right: 50px;
    }
    
    .getstarted-mob{
        display: flex;
        flex-direction: column;
       align-items: center;
        width: 100%;
        min-width: 200px;
        overflow: scroll;
        min-height: 100vh;
        height: 100%;
background: linear-gradient(0deg, #334155 0%, #334155 100%), #FFF;
    }
    .getstarted-mob::-webkit-scrollbar{
        display: none;
    }
    .getstarted-content-mob{
        display: flex;
        
max-width: 328px;
width: 100%;
gap: 30px;
min-height: 396px;
height: 100%;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-shrink: 0;
    }
.headingBox-mob{
    margin-top: 67px;
    display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
align-self: stretch;
}
.button-container-mob{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 32px;
align-self: stretch;
}
.create-project-mob{
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 
    border-radius: 4px;
border: none;

background: linear-gradient(90deg, #00C2FF -6.71%, #9746FF 88.41%);
}
.goto-myprojects{
    display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
background: #00C2FF;
border: none;
margin-bottom: 20px;
}
.getstated-image{
    max-width: 213.14px;
    width: 100%;
height: 222.819px;
flex-shrink: 0;
align-self: center;
}
.threedot-mob{
    display: inline-flex;
margin-top: 20px;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
border: none;
}
.threedots{
    display: flex;
width: 24px;
height: 24px;
/* padding: 0px 3.25px; */
justify-content: center;
align-items: center;
}
.threedotBox-dash{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 32px;
}
  }

