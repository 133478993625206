.new-project-container {
    height: 100vh;
    width: 100%;
    background: var(--pallate-blue-gray-800, #101010);
    display: flex;
}
body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

.button-container {
    z-index: 2;
    position: absolute;
    background-color: #fff;
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    margin-left: 32px;
    margin-top: 37px;
}

.back-button:hover {
    cursor: pointer;
}

.container-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
}

.back-button {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    background-color: #fff;
}

.arrow {
    width: 15.5px;
    height: 13.481px;
    flex-shrink: 0;
}

.container-right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.project-details {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    width: 100%;
    padding: 20px;
}

.project-instruction {
    display: flex;
    width: 100%;
    max-width: 603px;
    flex-direction: column;
    align-items: center;
    gap: 76px;
}

.instruction-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.instruction-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.project-inputs {
    display: flex;
    width: 100%;
    max-width: 603px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.input-box1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    align-self: stretch;
    color: #64748B;
}

.project-name {
    outline: none;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--pallate-gray-700, #374151);
    /* background: var(--pallate-blue-gray-900, #0F172A); */

    /* Text / base / normal-400 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    color: #64748B;
}

/* .input-box2 {
    display: flex;
    align-items: center;
    align-self: stretch;
    color: #64748B;
}

.upload-file {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--pallate-gray-700, #374151);
    border-bottom: 1px solid var(--pallate-gray-700, #374151);
    border-left: 1px solid var(--pallate-gray-700, #374151);
    border-right: none;
    background: var(--pallate-blue-gray-900, #0F172A);

    color: var(--light-text-secondary-text, #64748B);

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}

.browse-button {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0px 4px 4px 0px;
    background: var(--pallate-cyan-500, #06B6D4);
    border: none;
}

.browse-button:hover {
    cursor: pointer;
} */

.upload {
    display: flex;
    width: -webkit-fill-available;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--pallate-base-white, #FFF);
}

.upload-drag-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.createButton {
    display: flex;
    height: 43px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--pallate-purple-500, #A855F7);
    border: none;
}

.createButton:hover {
    cursor: pointer;
}

.upload-Icon {
    width: 98px;
    height: 98px;
}
.dashboard-mob{
    display: none;
   }
@media screen and (max-width: 900px)and (min-width:600px){
    .new-project-container {
        height: auto;
        width: 100%;
        background: var(--pallate-blue-gray-800, #101010);
        display: flex;
        flex-direction: column;
    }
    .container-left {
        width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
    }
    .container-right {
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
    }
  }
  @media screen and (max-width: 600px){
    .new-project-container {
         display: none;
    }
    .dashboard-mob{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height:100vh;
        height: 100%; 
        background: linear-gradient(0deg, #334155 0%, #334155 100%), #FFF;
    }
    .mob-bottom{
        display: flex;
max-width: 328px;
width: 100%;
min-height: 460px;
height: 100%;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-shrink: 0;
    }
.dash-heading-container{
    display: flex;
flex-direction: column;
align-items: center;
gap: 76px;
align-self: stretch;
}
.headings-box{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 32px;
align-self: stretch;
}
.headings-mob{
    display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
align-self: stretch;
}
.dash-button-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch; 
    max-width: 328px;
    width: 100%;
    margin-bottom: 20px;
}
.dash-input-box{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-radius: 4px;
}
.dash-input-container{
    display: flex;
padding: 8px 12px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--light-forms-border, #CBD5E1);
background: var(--light-forms-background, #FFF);
}
.dash-input-mob{
    flex: 1 0 0;
    color: var(--light-text-secondary-text, #64748B);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
border: none;
outline: none;
width: 100%;
}
 .dash-upload{
    display: flex;
    height: 43px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 
    border-radius: 4px;
background: #00C2FF;
border: none;
 }  
 .dash-create{
    display: flex;
height: 43px;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: none; 
}
.dash-image{
    max-width: 273.163px;
    width: 100%;
height: 199.24px;
flex-shrink: 0;
margin-bottom: 40px;
}
.threedotBox-cr{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 32px;
    max-width: 328px;
}
.back-dash-cr{
    display: inline-flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
cursor: pointer;
margin-top: 20px;
}
.dash-back-icon{
    display: flex;
    width: 24px;
    height: 24px;
    /* padding: 0px 3.25px; */
    justify-content: center;
    align-items: center;   
}
.file-dash{
    display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
}
.files{
    display: flex;
align-items: center;
gap: 12px;
}
.file-icon-box{
    display: flex;
height: 40px;
padding: 0.98px 1.176px 1.373px 1.176px;
justify-content: center;
align-items: center;
}
.icon-xl{
    width: 37.647px;
height: 37.647px;
flex-shrink: 0;
}
.cancel-icon{
    width: 24px;
    height: 24px;  
}
.remove-message-box{
    margin-top: -50px;
    display: flex;
    align-self: stretch;
    padding: 12px;
    align-items: flex-start;
    gap: 8px; 
    border-radius: 8px;
background: var(--pallate-blue-gray-100, #F1F5F9);
box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.25);
}
.remove-tick{
    display: flex;
width: 24px;
height: 24px;
padding: 5.879px 3.372px 5.75px 3.128px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
.remove-cross{
    display: flex;
width: 24px;
height: 24px;
padding: 6.281px 6.281px 6.301px 6.301px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
  }

