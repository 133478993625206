.myproject{
    display: none;
    } 
@media screen and (max-width:600px){
 .myproject{
width: 100%;
min-height: 100vh;
height: 100%;
background: linear-gradient(0deg, #334155 0%, #334155 100%), #FFF;
display:block;
/* display: flex;
flex-direction: column;
align-items: center; */
}  
.container{
    /* max-width: 328px; */
    padding: 0px 16px;
    /* width: 100%; */
    min-height: 100vh;
    height: 100%;
} 
.title{
    max-width: 328px;
    width: 100%;
    margin-top: 12px;
}
.input-box{
    display: flex;
    flex-direction: row;
    /* max-width: 328px; */
    width: 100%;
    align-items: flex-start;
    gap: 8px; 
    margin-top: 24px;
}
.input-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    flex: 1 0 0; 
    border-radius: 4px;
}
.input-section-mob{
    display: flex;
    /* min-width: 209.8px; */
    /* max-width: 300px; */
padding: 8px 12px;
flex-direction: row;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--light-forms-border, #CBD5E1);
background: var(--light-forms-background, #FFF);
width: 100%;
}
.input{
    width: 100%;
    flex: 1 0 0;
    color: var(--light-text-secondary-text, #64748B);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
border: none;
outline: none;
}
.filter{
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
background: #00C2FF;

}
.sort{
    display: flex;
width: 20px;
height: 20px;
padding: 3.542px 4.152px 3.542px 4.065px;
justify-content: center;
align-items: center;
}
.sort-Icon{
    width: 11.783px;
height: 12.917px;
flex-shrink: 0;
}
.recent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.recent-container{
    display: flex;
/* max-width: 328px; */
width: 100%;
align-items: flex-start;
gap: 16px;
margin-top: 24px;
flex-wrap: wrap;
overflow-y: auto;
}
.recent-container::-webkit-scrollbar{
    display: none;
}
.recent-file{
    display: flex;
max-width: 154px;
height: 201px;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 12px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--pallate-blue-gray-500, #64748B);
background: var(--pallate-blue-gray-600, #475569);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}
.chart-box{
    display: flex;
width: 102px;
height: 102px;
padding: 2.5px 3px 3.5px 3px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
.char-image{
    width: 96px;
height: 96px;
flex-shrink: 0;
}
.chart-detail{
    display: flex;
flex-direction: column;
align-items: flex-start;
}
.threedotBox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 32px;
    /* max-width: 328px; */
}
.back{
    display: inline-flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
cursor: pointer;
margin-top: 20px;
}
.back-icon{
    display: flex;
    width: 24px;
    height: 24px;
    /* padding: 0px 3.25px; */
    justify-content: center;
    align-items: center;   
}
.tab-menu-data{
    color: #FFF;
    width: 37%;
    display: inline-flex;
    height: 42px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 1; 
    border-radius: 8px 8px 0px 0px;
border-bottom: 2px solid var(--light-text-inverse-text, #FFF);
background: var(--pallate-blue-gray-500, #64748B);
}
.tab-menu-viz{
    color: #FFF;
    margin-left: 3px;
    display: flex;
    width: 50%;
    height: 42px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    /* gap: 8px; */
    flex-shrink: 1;
    border-radius: 8px 8px 0px 0px;
border-bottom: 2px solid var(--light-text-secondary-text, #FFF);
background: var(--pallate-blue-gray-800, #64748B);

}
.datas-option{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.tab-inactive-file{
    display: flex;
    height: 42px;
    width: 37%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    /* gap: 8px; */
    flex-shrink: 1;
    color: #64748B;
    border-radius: 8px 8px 0px 0px;
border-bottom: 2px solid var(--light-text-secondary-text, #64748B);
background: var(--pallate-blue-gray-800, #101010);
}
.tab-inactive-viz{
    margin-left: 3px;
    display: flex;
    width: 50%;
    height: 42px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    /* gap: 8px; */
    flex-shrink: 1;
    color: #64748B;
    border-radius: 8px 8px 0px 0px;
border-bottom: 2px solid var(--light-text-secondary-text, #64748B);
background: var(--pallate-blue-gray-800, #101010);
}
.save-more-mob{
    display: flex;
width: 100%;
height: 43px;
padding: 8px 12px;
border: none;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
background: linear-gradient(90deg, #00C2FF -6.71%, #9746FF 88.41%);
}
.pluss{
    display: flex;
width: 20px;
height: 20px;
padding: 3.542px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
.plus-icon-mob{
    width: 12.917px;
height: 12.917px;
flex-shrink: 0;
}
}
