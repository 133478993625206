.chat-container {
    height: 100vh;
    width: 100%;
    background: var(--pallate-blue-gray-800, #101010);
    display: flex;
    overflow: hidden;
}

.input-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.chat-input {
    position: relative;
    display: flex;
    margin-bottom: 18px;
    max-width: 916px;
    width: 100%;
    height: 105px;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 1;
    border-radius: 4px;
}

.chat-box {
    
    resize: none;
    color:var(--light-text-secondary-text, #64748B);
    outline: none;
    display: flex;
    padding: 12px 40px 12px 12px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--pallate-gray-600, #4B5563);
    /* background: var(--pallate-gray-900, #111827); */
}

.sidebar {
    width: 321px;
    height: 100vh;
    flex-shrink: 0;
    background: var(--pallate-blue-gray-700, #334155);
}

.sidebar-active {
    width: 321px;
    height: 100vh;
    flex-shrink: 0;
    background: var(--pallate-blue-gray-700, #334155);
}


.chat-right {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chat-page {
    display: flex;
    justify-content: center;
    height: -webkit-fill-available;
    overflow-y: auto;
}
.chat-page::-webkit-scrollbar {
display: none;
}

.sendButton-container {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 12px;
}


.send-button {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #9746FF;
    border: none;
    color: #FFF;
}

.send-button:hover {
    cursor: pointer;
}

.send {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3.249px 3.251px 3.27px 3.225px;
    justify-content: center;
    align-items: center;
}

.button-container1 {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    background: var(--pallate-blue-gray-500, #64748B);
    margin-left: 32px;
    margin-top: 37px;
}

.back-button1 {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}
.back-button1:hover{
    cursor: pointer;
}
.arrow1 {
    width: 15.5px;
    height: 13.481px;
    flex-shrink: 0;
}

.history-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding-left: 32px;
    padding-right: 26px;
    padding-top: 12px;
}

.history-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: calc(100vh - 230px);
    overflow-y: auto;
}
.history-box-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: calc(100vh - 358px - 20px); /* 358px is the height from top to question bar start and 20px is the bottom padding*/
    overflow-y: auto;
}
.history-box-tab::-webkit-scrollbar{
    /* width: 3px; */
    display: none;
   
}
.history-box::-webkit-scrollbar{
    /* width: 3px; */
    display: none;
   
}
/* ::-webkit-scrollbar-thumb {
    background: #64748B;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #64748B;
    
} */

.history {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom:1px;
}

.hostory-content {
    cursor: pointer;
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid var(--pallate-blue-gray-400, #94A3B8);
    transition: .2s ease-in-out;
    
}
.hostory-content:hover{
    background-color: #475569;
}
.hostory-content:hover .historyMessage{
    color: #FFF;
}
.hostory-content:hover::after{
color: #00C2FF;
}
.historySearch{
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
border: 1px solid var(--light-forms-border, #CBD5E1);
background: var(--light-forms-background, #FFF);
margin-left: 32px;
    margin-right: 26px;
    margin-top: 10px;
}
.searchInput{
    width: 100%;
    outline: none;
    color: var(--light-text-secondary-text, #64748B);

/* Text / sm / normal-400 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
border:none;
}
/* .line {
    margin-right: 32px;
    margin-left: 24px;
    max-width: 96%;
    width: 100%;
    height: 1px;
    background: #475569;
} */

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #475569;
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0 32px 0 24px;
}

.menu {
    display: none;
}

.create {
    display: inline-flex;
    padding: 10px 12px;
    flex-shrink:0;
    justify-content: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    margin-top: 36px;
    margin-bottom: 16px;
    background: #9746FF;
    color: #FFF;
}

.create:hover {
    cursor: pointer;
}

.buttons-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 29px;
    margin-right: 32px;
}

.buttons {
    border: none;
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    background: var(--pallate-blue-gray-500, #64748B);
}

.buttons:hover {
    cursor: pointer;
}

.icons {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4.25px 3.25px;
    justify-content: center;
    align-items: center;
}

.result-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    height: 80%;
    flex-shrink: 0;
    transition: .5s ease-in-out;
    margin-right: 112px;
    /* border-right:2px solid var(--pallate-gray-600, #4B5563) ; */
}
.result-container::-webkit-scrollbar{
    display: none;
}
/* ::-webkit-scrollbar {
    width: 2px;
    height: 92px;
    background: var(--pallate-gray-600, #4B5563);
    flex-shrink: 0;
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: #9746FF;
    border-radius: 5px;
    width: 4px;
  } */
  

.chart-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.chart {
    max-width: 650px;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 20px;
}

.canvas {
    width: 361px;
    height: 361px;
    flex-shrink: 0;
    border-radius: 361px;
    margin-top: 20px;
}

/* .chart-change {
    display: none;
} */

.edit-refresh {
    display: none;
}

.result-container:hover .edit-refresh {
    display: inline-flex;
    margin-right: -50px;
    padding: 16px;
    align-items: flex-start;
    gap: 34px;
    border-radius: 16px;
    background: var(--pallate-blue-gray-700, #334155);
    transition: 0.5s ease-in-out;
}

/* .result-container:hover .chart-change {
     margin-top: 50px; 
    display: inline-flex;
    padding: 16px;
    align-items: flex-start;
    gap: 34px;
    border-radius: 16px;
    background: var(--pallate-blue-gray-700, #334155);
     transition: 1s ease-in-out; 
} */
.chart-icons{
    width: 24px;
    height: 24px;
}
.chart-icons-div button{
    padding: 0;
    display: block;
    min-width: 24px;
    /* height: 24px; */
}
.export {
    display: flex;
    width: 617px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    background: var(--pallate-blue-gray-900, #0F172A);
    box-shadow: 0px 0px 148px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(38px);
    position: relative;
}

.export-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.export-captions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.close-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 22px;
    top: 16px;
}

.close-icon:hover {
    cursor: pointer;
}

.export-options {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.excel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.credits_info{
    display: inline-flex;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid var(--pallate-blue-gray-500, #64748B);
    background: var(--pallate-blue-gray-600, #475569);
}

.export-icon {
    width: 60px;
    height: 60px;
}

.radio-btn {
    display: flex;
    align-items: center;
    gap: 8px;
}

.radio-btn :hover {
    cursor: pointer;
}

.input-radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
}

.button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.button-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.export-button {
    border: none;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: linear-gradient(0deg, #9746FF 0%, #9746FF 100%), #00C2FF;
}

.button-box :hover {
    cursor: pointer;
}

.save-report-container {
    display: flex;
    width: 617px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    background: var(--pallate-blue-gray-900, #0F172A);
    box-shadow: 0px 0px 148px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(38px);
    position: relative;
}

.title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.titleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.text-input {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--pallate-blue-gray-600, #475569);
    background: var(--pallate-blue-gray-800, #101010);
}

.save-input {
    color: var(--pallate-blue-gray-400, #94A3B8);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    flex: 1 0 0;
    border: none;
    outline: none;
    background-color: transparent;
}

.text-area {
    display: flex;
    width: 916px;
    height: 105px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
}
.chart-none{
    display: none;
}
.dataSelection{
    display: flex;
    margin-top: 18px;
padding: 8px 12px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--light-forms-border, #CBD5E1);
background: var(--light-forms-background, #FFF);
}
.selectDatas{
    display: flex;
    
min-width: 446px;
width: 100%;
flex-direction: column;
align-items: flex-start;
gap: 8px;
outline: none;
border: none;
background-color: transparent;
}
.tab-history{
    display: inline-flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
}
.tab-history-img{
    width: 24px;
height: 24px;
}
.sidebar-tab{
    width: 100%;
border-radius: 15px;
    height: 70vh;
    flex-shrink: 0;
    background: var(--pallate-blue-gray-700, #334155);
}
.history-container-tab{
padding: 20px;
/* width: 94%; */
}
.history-tab{
    width: 100%;
    background: var(--pallate-blue-gray-600, #475569);
    border-radius: 10px;
}



@media screen and (max-width: 900px)and (min-width:600px) {
    .menu {
        display: block;
    }
.sidebar{
    display: none;
}
    .sidebar-active {
        width: 321px;
        height: 100vh;
        position: fixed;
        background: var(--pallate-blue-gray-700, #334155);
    }

    .button-container1 {
        display: inline-flex;
        padding: 8px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 20px;
        background: var(--pallate-blue-gray-500, #64748B);
        margin-left: 72px;
        /* margin-top: 37px; */
    }
    .chart {
        max-width: 480px;
        width: 100%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .result-container:hover .chart-change {
        /* margin-top: 40px; */
        display: block;
        padding: 14px;
        align-items: flex-start;
        gap: 34px;
        border-radius: 16px;
        background: var(--pallate-blue-gray-700, #334155);
       padding-left: 0px;
    }
    .selectDatas{
        display: flex;
    min-width: 350px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    outline: none;
    border: none;
    background-color: transparent;
    }
    .dataSelection{
        display: flex;
    padding: 2px 12px;
    height: 25px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
    margin-top: 28px;
    }
    .create {
        display: none;
    }
  
   .threeDot{
    display: flex;
width: 24px;
height: 24px;
/* padding: 0px 3.25px; */
justify-content: center;
align-items: center;
   }
   .result-container{
    border-right: 0; 
    transition: 1s ease-in-out;
    margin-right: 0px;
   }
   .result-container::-webkit-scrollbar{
    display: none;
   }
   .top-bar1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 32px 0 24px;
}
.button-container2 {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
    /* margin-top: 37px; */
    margin-bottom: 10px;
}
.back-button2{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}.back-button2:hover{
    cursor: pointer;
}
.tab-history{
    display: inline-flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
/* margin-top: 37px; */
margin-bottom: 10px;
border: none;
}
.tab-history-img{
    width: 24px;
height: 24px;
} 
.tab-history:hover{
    cursor: pointer;
}
.tab-history-img{
    width: 24px;
height: 24px;
}
.sidebar-tab{
    width: 100%;

    height: auto;
    flex-shrink: 0;
    background: var(--pallate-blue-gray-700, #334155);
}
.send-button {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #9746FF;
    border: none;
    color: #FFF;
    margin-right: 26px;
}
.credits_info_mob{
    display: flex;
    max-width: 190px;
    width: 100%;
    align-self: center;
    padding: 4px 8px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid var(--pallate-blue-gray-500, #64748B);
    background: var(--pallate-blue-gray-600, #475569);
    }
}

@media screen and (max-width: 720px)and (min-width:540px) {
    .chat-container {
        height: 100vh;
        width: 100%;
        background: var(--pallate-blue-gray-800, #101010);
        display: flex;
    }
}
@media screen and (max-width: 600px){
    .credits_info_mob{
    display: flex;
    max-width: 190px;
    width: 100%;
    height: 24px;
    align-self: center;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid var(--pallate-blue-gray-500, #64748B);
    background: var(--pallate-blue-gray-600, #475569);
    }
    .menu {
        display: block;
    }
.sidebar{
    display: none;
}
    .sidebar-active {
        width: 321px;
        height: 100vh;
        position: fixed;
        background: var(--pallate-blue-gray-700, #334155);
    }

    .button-container1 {
        display: inline-flex;
        padding: 8px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 20px;
        background: var(--pallate-blue-gray-500, #64748B);
        margin-left: 72px;
        /* margin-top: 37px; */
    }
    .chart {
        max-width: 300px;
        width: 100%;
        flex-shrink: 1;
        display: flex;
        /* justify-content: center; */
        margin-top: 10px;
    }
    .result-container:hover .chart-change {
        /* margin-top: 40px; */
        display: block;
        padding: 14px;
        align-items: flex-start;
        gap: 34px;
        border-radius: 16px;
        background: var(--pallate-blue-gray-700, #334155);
       
    }
    .selectDatas{
        display: flex;
    min-width: 350px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    outline: none;
    border: none;
    background-color: transparent;
    }
    .dataSelection{
        display: flex;
    padding: 2px 12px;
    height: 25px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
    margin-top: 28px;
    }
    .create {
        display: none;
    }
  
   .threeDot{
    display: flex;
width: 24px;
height: 24px;
/* padding: 0px 3.25px; */
justify-content: center;
align-items: center;
   }
  
   .result-container::-webkit-scrollbar{
    display: none;
   }
   .top-bar1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 0 20px;
}
.button-container2 {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
    /* margin-top: 37px; */
    margin-bottom: 10px;
}
.back-button2{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}.back-button2:hover{
    cursor: pointer;
}
.tab-history{
    display: inline-flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background: var(--pallate-blue-gray-600, #475569);
/* margin-top: 37px; */
margin-bottom: 10px;
border: none;
}
.tab-history-img{
    width: 24px;
height: 24px;
} 
.tab-history:hover{
    cursor: pointer;
}
.tab-history-img{
    width: 24px;
height: 24px;
}
.sidebar-tab{
    width: 100%;

    height: auto;
    flex-shrink: 0;
    background: var(--pallate-blue-gray-700, #334155);
}
.buttons-container {
    display:none;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 29px;
    margin-right: 32px;
}
.chat-page {
    display: flex;
    justify-content: center;
    height: -webkit-fill-available;
    overflow-y: auto;
    padding:0px 20px 20px 20px;
}
.input-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    padding-left: 0;
} 
.result-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    height: 80%;
    flex-shrink: 0;
    transition: .5s ease-in-out;
    margin-right: 0px;
   }
   .result-container::-webkit-scrollbar{
    display: none;
   } 
.chat-box {  
    resize: none;
    color:var(--light-text-secondary-text, #64748B);
    /* max-width: 300px; */
    width: 100%;
    outline: none;
    display: flex;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--light-forms-border, #CBD5E1);
    background: var(--light-forms-background, #FFF);
}
.sendButton-container {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 0px;
    position: static;
}


.send-button {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 50px;
    background: #9746FF;
    border: none;
    color: #FFF;
}
.chat-input {
    display: flex;
    margin-bottom: 18px;
    width: 100%;
    height:45px;
    /* height: fit-content; */
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 1;
    border-radius: 4px;
    border: none;
    position: static;
}
.save-report-container {
    display: flex;
    max-width: 300px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    background: var(--pallate-blue-gray-900, #0F172A);
    box-shadow: 0px 0px 148px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(38px);
    position: relative;
}
}